/* eslint-disable prettier/prettier */ /* eslint-disable prettier/prettier */
<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="h4 semiBold">Descuentos</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="light br-8" elevation="0">
          <v-card-text>
            <div class="d-flex align-center justify-space-between">
              <div class="flex-grow-1">
                <LabelComponent text="N° Cotización" />
                <TextField :fields="text_search" />
              </div>
              <div class="px-2">
                <LabelComponent text="Fecha" />
                <div class="d-flex dateGroup">
                  <DatePicker
                    :date="dateStart"
                    placeholder="Desde"
                    @input="dateStart = $event"
                    color="#A9A9A9"
                    background-color="white"
                    width="100"
                    icon="left"
                  />
                  <DatePicker
                    :date="dateEnd"
                    placeholder="Hasta"
                    @input="dateEnd = $event"
                    color="#A9A9A9"
                    background-color="white"
                    icon="left"
                  />
                </div>
              </div>

              <div class="flex-grow-1">
                <LabelComponent text="Estado" />
                <v-select
                  v-model="selectedEstado"
                  :items="estados"
                  item-text="label"
                  item-value="value"
                  label="Select"
                  persistent-hint
                  return-object
                  outlined
                  dense
                  append-icon="mdi-chevron-down"
                  single-line
                ></v-select>
              </div>
              <div class="flex-grow-1 moveY">
                <v-btn
                  color="primary"
                  class="text-none mx-4"
                  width="140"
                  @click="loadDescuentos"
                  rounded
                  :disabled="disableFilter"
                >
                  <v-icon left>mdi-filter</v-icon>
                  Filtrar
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="d-flex justify-space-between align-center">
          <div>
            <LabelComponent text="Registros a mostrar" />
            <v-select
              :items="limitOptions"
              v-model="limit"
              return-object
              outlined
              dense
              append-icon="mdi-chevron-down"
              @change="handleChangeLimit"
              background-color="white"
            ></v-select>
          </div>
          <div>
            <v-btn
              :loading="loadingData"
              @click="loadDescuentos"
              color="primary"
              outlined
              small
              fab
              class="mr-3"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              text
              class="text-none"
              @click="Download()"
              :loading="isDownloading"
              rounded
            >
              <span>Descargar archivo</span>
              <v-icon right>mdi-download</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" v-if="loadingData">
        <v-skeleton-loader type="table-thead"></v-skeleton-loader>
        <v-skeleton-loader type="table-row-divider@3"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" v-else>
        <v-data-table
          :headers="headers"
          :items="isfiltering ? FilteredData : descuentos"
          :search="text_search.text.replace(/[^A-Z0-9]/gi, '')"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:item.estado="{ item }">
            <v-row align="center" justify="center">
              <div
                class="d-flex align-center flex-row"
                v-if="item.estado === 'REC'"
              >
                <v-chip color="#F25C53">
                  <span class="p text-white">Rechazado</span>
                </v-chip>
              </div>

              <div
                class="d-flex align-center flex-row"
                v-if="item.estado === 'A'"
              >
                <v-chip color="#06844D">
                  <span class="p text-white">Aprobado</span>
                </v-chip>
              </div>

              <div
                class="d-flex"
                v-else-if="item.estado === 'PEN' || item.estado == 1"
              >
                <v-chip color="#FAB76A">
                  <span class="p">Pendiente</span>
                </v-chip>
              </div>
            </v-row>
          </template>
          <template v-slot:item.eye="{ item }">
            <v-btn @click="EyesClicked(item)" icon name="boton_eye">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="text-center mt-4">
      <small>Páginas</small>
      <Pagination
        v-if="paginationReady"
        :pages="pages"
        :changePage="(e) => loadDescuentos(e)"
        :currentPage="pagina"
      />
    </div>
    <FinalModal
      v-click-outside="CloseFinalModal"
      :dataUser="modalData"
      :open="FinalModal"
      :isAuthorized="isAuthorized"
      @CloseFinalModal="CloseFinalModal"
    />
    <ActionModal
      :open="Modal"
      :userdata="modalData"
      @close="closeModal"
      @authorize="authorize"
      @rechazar="rechazar"
      :loadingstatus="loadingstatus"
      :loadingrechazar="loadingrechazar"
      :item="selectedItem"
    />

    <ModalRejectReason
      v-if="modalRejectReason"
      :rejected="reject"
      :toggle="toggleRejectReason"
      :item="selectedItem"
    />
    <ModalRequestRejected
      v-if="modalRequestRejected"
      :toggle="toggleRequestRejected"
      :item="selectedItem"
    />
    <ModalRequestApproved
      v-if="modalRequestSuccess"
      :toggle="toggleRequestSuccess"
      :item="selectedItem"
    />
    <ModalDiscountDetail
      v-if="modalDiscountDetail"
      :toggle="toggleRejected"
      :item="selectedItem"
      :type="selectedItem.estado"
      :loadingReject="loadingrechazar"
      :reject="rechazar"
      :approve="authorize"
    />
  </v-container>
</template>
<script>
import ActionModal from "@/components/Modal/Descuentos/TablaDescuentos/Modal-confirm-descuento";
import FinalModal from "@/components/Modal/Descuentos/TablaDescuentos/Modal-Descuento-Final";
import ModalRejectReason from "@/components/Modal/Descuentos/TablaDescuentos/Modal-discount-reject-reason";
import ModalRequestRejected from "@/components/Modal/Descuentos/TablaDescuentos/Modal-discount-request-rejected";
import ModalRequestApproved from "@/components/Modal/Descuentos/TablaDescuentos/Modal-discount-request-approved";
import ModalDiscountDetail from "@/components/Modal/Descuentos/TablaDescuentos/Modal-discount-detail";
import TextField from "@/components/Inputs/TextField";
import DatePicker from "@/components/Inputs/DatePicker";
import Pagination from "@/components/Pagination/Pagination.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import { mapActions } from "vuex";

export default {
  components: {
    FinalModal,
    ActionModal,
    TextField,
    DatePicker,
    LabelComponent,
    ModalDiscountDetail,
    ModalRejectReason,
    ModalRequestRejected,
    ModalRequestApproved,
    Pagination,
  },

  data() {
    return {
      modalPending: false,
      modalRejectReason: false,
      modalRequestRejected: false,
      modalRequestSuccess: false,
      modalDiscountDetail: false,
      loadingData: false,
      dateStart: null,
      dateEnd: null,
      permission: this.$store.state.Authentication.perfil,
      isfiltering: false,
      isAuthorized: false,
      FinalModal: false,
      FilteredData: [],
      filter: "",
      searchLoading: false,
      Modal: false,
      action: "",
      selectedItem: "",
      paginationReady: false,
      offset: 0,
      pages: 0,
      pagina: 1,
      limit: 10,
      isDownloading: false,
      headers: [
        {
          text: "N° Cotización",
          value: "cotizacion",
          align: "center",
        },
        {
          text: "Fecha",
          value: "fecha_registro",
          align: "center",
        },
        {
          text: "Hora",
          value: "hora_registro",
          align: "center",
        },
        {
          text: "Plan",
          value: "plan",
          align: "center",
        },
        {
          text: "Concesionario",
          value: "concesionario",
          align: "center",
        },
        {
          text: "Sucursal",
          value: "sucursal",
          align: "center",
        },
        {
          text: "% descuento",
          value: "monto_descuento",
          align: "center",
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
        },

        {
          text: "Acción",
          value: "eye",
          align: "center",
          sortable: false,
          width: "5%",
        },
      ],
      selectedEstado: { val: "ALL", label: "Todos" },
      estados: [
        { val: "ALL", label: "Todos" },
        { val: "REC", label: "Rechazado" },
        { val: "A", label: "Aprobado" },
        { val: "PEN", label: "Pendiente" },
      ],
      limitOptions: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "Todos",
          value: 0,
        },
      ],
      Name: {
        label: "A cargo de",
        combo: true,
        answers: [],
        selected: "",
      },
      text_search: {
        placeholder: "Ingrese n° cotización",
        text: "",
        icon: "mdi-magnify",
      },
      descuentos: [],
      modalData: [],
      loadingstatus: false,
      loadingrechazar: false,
      errormsg: "",
      errorModal: false,
    };
  },
  methods: {
    ...mapActions("Discounts", [
      "getDiscounts",
      "approveDiscount",
      "rejectDiscount",
      "getFile",
    ]),
    async Download() {
      this.isDownloading = true;
      this.getFile(this.getRequest())
        .then((res) => {
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `discounts_${new Date()}.xlsx`; // Nombre del archivo
          downloadLink.click();
          URL.revokeObjectURL(downloadLink.href);
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    togglePending() {
      this.modalPending = !this.modalPending;
    },
    toggleRejectReason() {
      this.modalRejectReason = !this.modalRejectReason;
    },
    toggleRequestRejected() {
      this.modalRequestRejected = !this.modalRequestRejected;
    },
    toggleRequestSuccess() {
      this.modalRequestSuccess = !this.modalRequestSuccess;
    },
    toggleRejected() {
      this.modalDiscountDetail = !this.modalDiscountDetail;
    },

    toggleModal(modal) {
      this[modal] = !this[modal];
    },
    closeError() {
      this.errorModal = false;
    },
    CloseFinalModal() {
      this.FinalModal = false;
    },
    async EyesClicked(item) {
      this.selectedItem = item;
      this.modalDiscountDetail = true;
    },

    async authorize() {
      this.loadingstatus = true;
      const approve = await this.approveDiscount(this.selectedItem.id);
      if (approve.success) {
        this.modalDiscountDetail = false;
        this.modalRequestSuccess = true;
        this.loadDescuentos(this.pagina);
      }
    },

    rechazar() {
      this.modalDiscountDetail = false;
      this.modalRejectReason = true;
    },
    async reject(e) {
      // this.loadingrechazar = true;
      const reject = await this.rejectDiscount({
        id: e.id,
        motivo: e.motivo,
      });
      if (reject.success) {
        this.modalRejectReason = false;
        this.modalRequestRejected = true;
        this.loadDescuentos();
      }
    },
    closeModal() {
      this.userdata = "";
      this.Modal = false;
      // this.loadDescuentos();
    },

    getRequest() {
      const request = { limit: this.limit, page: this.pagina };
      if (this.text_search.text) {
        request.cotizacion = this.text_search.text;
      }
      if (this.selectedEstado && this.selectedEstado.val !== "ALL") {
        request.estado = this.selectedEstado.val;
      }

      if (this.dateStart) {
        request.fecha_solicitud_desde = this.dateStart;
      }
      if (this.dateEnd) {
        request.fecha_solicitud_hasta = this.dateEnd;
      }
      return request;
    },
    async loadDescuentos(e) {
      this.pagina = e;
      this.loadingData = true;
      const descuentos = await this.getDiscounts(this.getRequest());

      this.descuentos = descuentos.solicitudes;
      this.pages = descuentos.paginacion.numeroPaginas || 1;
      this.pagina = descuentos.paginacion.pagina;
      this.paginationReady = true;
      this.loadingData = false;
    },
    handleChangeLimit(e) {
      this.limit = e.value;
      this.loadDescuentos();
    },
  },
  computed: {
    usermodal() {
      return this.$store.getters.usermodal;
    },
    userData() {
      return this.$store.getters.User;
    },
    disableFilter() {
      return (
        (this.dateStart && !this.dateEnd) || (!this.dateStart && this.dateEnd)
      );
    },
  },
  mounted() {
    this.loadDescuentos();
  },
};
</script>

<style scoped src="../../assets/CSS/SimpleTable.css"></style>

<style>
.moveY {
  transform: translateY(-3px) !important;
}
</style>

<!-- @import "../../assets/CSS/ServiceStyle.css"; -->
